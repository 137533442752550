const initBanner = () => {
  const bannerText = document.querySelector('.banner-text');

  if (bannerText) {
    window.addEventListener('scroll', (event) => {
      bannerText.style.backgroundColor = `rgba(30,34,67,${0.2 + (window.scrollY / window.innerHeight)})`;
    })
  }
}

export { initBanner }
