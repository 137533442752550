const initCgv = () => {
  const radios = document.querySelectorAll('form input[type="radio"]');
  const labels = document.querySelectorAll('form label');

  const highlightLabel = () => {
    labels.forEach((l) => {
      l.classList.remove('selected');
    });

    document.querySelector('form input[type="radio"]:checked').parentNode.classList.add('selected');
  };

  radios.forEach((radio) => {
    radio.addEventListener('change', highlightLabel);
  });

  highlightLabel();
}

export { initCgv }
