import { initTouchNav } from './touch_nav.js';

const initTestimonials = () => {
  const repeat = false;
  const container = document.querySelector('.slider-container');

  let slide = document.querySelectorAll('.slider-single');
  let slideTotal = slide.length - 1;
  let slideCurrent = -1;

  const initNav = () => {
    slide.forEach((elem, i) => {
      elem.addEventListener('click', () => {
        if(elem.className.includes('proactive'))
          slideRight();
        if(elem.className.includes('preactive'))
          slideLeft();
      });
    });

    initTouchNav(container, slideLeft, slideRight);
  }

  const initBullets = () => {
    const bulletContainer = document.createElement('div');

    bulletContainer.classList.add('bullet-container');

    slide.forEach((elem, i) => {
      const bullet = document.createElement('div');

      bullet.classList.add('bullet')
      bullet.id = `bullet-index-${i}`
      bullet.addEventListener('click', () => {
        goToIndexSlide(i);
      })
      bulletContainer.appendChild(bullet);
      elem.classList.add('proactivede');
    })
    container.appendChild(bulletContainer);
  }

  const slideInitial = () => {
      initBullets();
      initNav();

      setTimeout(slideRight, 500);
  }

  const updateBullet = () => {
      document.querySelector('.bullet-container').querySelectorAll('.bullet').forEach((elem, i) => {
        elem.classList.remove('active');
        if (i === slideCurrent)
          elem.classList.add('active');
      })

      checkRepeat();
  }

  const checkRepeat = () => {
      if (!repeat) {
          if (slideCurrent === slide.length - 1) {
              slide[0].classList.add('not-visible');
              slide[slide.length - 1].classList.remove('not-visible');
          }
          else if (slideCurrent === 0) {
              slide[slide.length - 1].classList.add('not-visible');
              slide[0].classList.remove('not-visible');
          } else {
              slide[slide.length - 1].classList.remove('not-visible');
              slide[0].classList.remove('not-visible');
          }
      }
  }

  const slideRight = () => {
    if (slideCurrent < slideTotal)
      slideCurrent++;
    else
      slideCurrent = 0;

    if (slideCurrent > 0)
      var preactiveSlide = slide[slideCurrent - 1];
    else
      var preactiveSlide = slide[slideTotal];

    var activeSlide = slide[slideCurrent];

    if (slideCurrent < slideTotal)
      var proactiveSlide = slide[slideCurrent + 1];
    else
      var proactiveSlide = slide[0];

    slide.forEach((elem) => {
      var thisSlide = elem;
      if (thisSlide.classList.contains('preactivede')) {
        thisSlide.classList.remove('preactivede');
        thisSlide.classList.remove('preactive');
        thisSlide.classList.remove('active');
        thisSlide.classList.remove('proactive');
        thisSlide.classList.add('proactivede');
      }
      if (thisSlide.classList.contains('preactive')) {
        thisSlide.classList.remove('preactive');
        thisSlide.classList.remove('active');
        thisSlide.classList.remove('proactive');
        thisSlide.classList.remove('proactivede');
        thisSlide.classList.add('preactivede');
      }
    });
    preactiveSlide.classList.remove('preactivede');
    preactiveSlide.classList.remove('active');
    preactiveSlide.classList.remove('proactive');
    preactiveSlide.classList.remove('proactivede');
    preactiveSlide.classList.add('preactive');

    activeSlide.classList.remove('preactivede');
    activeSlide.classList.remove('preactive');
    activeSlide.classList.remove('proactive');
    activeSlide.classList.remove('proactivede');
    activeSlide.classList.add('active');

    proactiveSlide.classList.remove('preactivede');
    proactiveSlide.classList.remove('preactive');
    proactiveSlide.classList.remove('active');
    proactiveSlide.classList.remove('proactivede');
    proactiveSlide.classList.add('proactive');

    updateBullet();
  }

  const slideLeft = () => {
      if (slideCurrent > 0)
        slideCurrent--;
      else
        slideCurrent = slideTotal;

      if (slideCurrent < slideTotal)
        var proactiveSlide = slide[slideCurrent + 1];
      else
        var proactiveSlide = slide[0];

      var activeSlide = slide[slideCurrent];

      if (slideCurrent > 0)
        var preactiveSlide = slide[slideCurrent - 1];
      else
        var preactiveSlide = slide[slideTotal];

      slide.forEach((elem) => {
        var thisSlide = elem;
        if (thisSlide.classList.contains('proactive')) {
          thisSlide.classList.remove('preactivede');
          thisSlide.classList.remove('preactive');
          thisSlide.classList.remove('active');
          thisSlide.classList.remove('proactive');
          thisSlide.classList.add('proactivede');
        }
        if (thisSlide.classList.contains('proactivede')) {
          thisSlide.classList.remove('preactive');
          thisSlide.classList.remove('active');
          thisSlide.classList.remove('proactive');
          thisSlide.classList.remove('proactivede');
          thisSlide.classList.add('preactivede');
        }
      });

      preactiveSlide.classList.remove('preactivede');
      preactiveSlide.classList.remove('active');
      preactiveSlide.classList.remove('proactive');
      preactiveSlide.classList.remove('proactivede');
      preactiveSlide.classList.add('preactive');

      activeSlide.classList.remove('preactivede');
      activeSlide.classList.remove('preactive');
      activeSlide.classList.remove('proactive');
      activeSlide.classList.remove('proactivede');
      activeSlide.classList.add('active');

      proactiveSlide.classList.remove('preactivede');
      proactiveSlide.classList.remove('preactive');
      proactiveSlide.classList.remove('active');
      proactiveSlide.classList.remove('proactivede');
      proactiveSlide.classList.add('proactive');

      updateBullet();
  }

  const goToIndexSlide = (index) => {
    const sliding = (slideCurrent > index) ? () => slideRight() : () => slideLeft();
    while (slideCurrent !== index)
      sliding();
  }

  slideInitial();
};

export { initTestimonials };
