const initTouchNav = (container, leftFunction, rightFunction) => {
  let touchX, deltaX;

  container.addEventListener('touchstart', e => {
    touchX = e.touches[0].clientX;
  });

  container.addEventListener('touchend', e => {
    deltaX = e.changedTouches[0].clientX - touchX;

    if(deltaX < -100)
      rightFunction();
    else if(deltaX > 100)
      leftFunction();
  });
}

export { initTouchNav };
