import { initTouchNav } from './touch_nav.js';

const initCounselors = () => {
  let activeIndex = 0;
  let element = document.querySelector('section.counselors')

  const leftArrow = element.querySelector('.arrow.prev');
  const rightArrow = element.querySelector('.arrow.next');

  const getIndex = (index) => {
    if(index < 0)
      index += counselors.length;

    if(index >= counselors.length)
      index -= counselors.length;

    return index;
  };

  const bufferEdgeItems = () => {
    bufferItem(element.querySelector('.item.prev'), -1);
    bufferItem(element.querySelector('.item.next'), 1);
  };

  const bufferItem = (item, variation) => {
    let index = getIndex(activeIndex + variation);
    let counselor = counselors[index];

    item.querySelector('.prev.top').style.backgroundImage = "url('" + counselors[getIndex(index - 2)].photo + "')";
    item.querySelector('.prev.bottom').style.backgroundImage = "url('" + counselors[getIndex(index - 1)].photo + "')";
    item.querySelector('.next.top').style.backgroundImage = "url('" + counselors[getIndex(index + 1)].photo + "')";
    item.querySelector('.next.bottom').style.backgroundImage = "url('" + counselors[getIndex(index + 2)].photo + "')";
    item.querySelector('h3').innerHTML = counselor.first_name;
    item.querySelector('p.languages .flags').innerHTML = counselor.languages;
    item.querySelector('p.bio').innerHTML = counselor.before;

    item.id = "item-" + index;
  };

  const showItem = (variation) => {
    let index = getIndex(activeIndex + variation);
    let counselor = counselors[index];

    let prevItem = element.querySelector('.item.prev');
    let nextItem = element.querySelector('.item.next');
    let activeItem = element.querySelector('.item.active');

    activeIndex = index;

    element.querySelector('a.more').href = counselor.link;
    element.querySelector('a.more').innerHTML = counselor.label;
    element.querySelector('.portrait').style.backgroundImage = "url('" + counselor.photo + "')";

    if(variation == -1) {
      activeItem.classList.remove('active');
      activeItem.classList.add('next');

      prevItem.classList.remove('prev');
      prevItem.classList.add('active');

      nextItem.classList.remove('next');
      nextItem.classList.add('prev');

      bufferItem(nextItem, -1)
    } else {
      activeItem.classList.remove('active');
      activeItem.classList.add('prev');

      prevItem.classList.remove('prev');
      prevItem.classList.add('next');

      nextItem.classList.remove('next');
      nextItem.classList.add('active');

      bufferItem(prevItem, 1)
    }
  };

  const initNav = () => {
    bufferEdgeItems();

    leftArrow.addEventListener('click', () => { showItem(-1); });
    rightArrow.addEventListener('click', () => { showItem(1); });

    initTouchNav(
      element,
      () => { showItem(-1); },
      () => { showItem(1); }
    );
  };

  initNav();
}

export { initCounselors };
