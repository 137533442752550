const initSeminarsSelect = () => {
  const seminarsSelect = document.querySelector('#seminars_list select');
  const seminarsBlocks = document.querySelectorAll('#seminars_list .row div');

  const seminarsChange = () => {
    seminarsBlocks.forEach((item, i) => {
      if(item.classList.contains(seminarsSelect.value))
        item.classList.remove('hide');
      else
        item.classList.add('hide');
    });
  };

  seminarsSelect.addEventListener('change', seminarsChange);

  seminarsChange();
}

export { initSeminarsSelect }
