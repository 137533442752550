const initRecordedSessionsForm = () => {
  const recordedSessionsSubmit = document.querySelector('#recorded_sessions_list input[type=submit]');
  const recordedSessionsSelects = document.querySelectorAll('#recorded_sessions_list select');

  recordedSessionsSelects.forEach((item) => {
    item.addEventListener('change', () => {
      recordedSessionsSubmit.click();
    })
  });
}

export { initRecordedSessionsForm }
