import { initTouchNav } from './touch_nav.js';

const contentCarousel = (element, numberOfElements) => {
  const items = element.querySelectorAll('.item');
  const leftArrow = element.querySelector('.arrow.prev');
  const rightArrow = element.querySelector('.arrow.next');

  let itemsPerSwipe;
  let activeIndex = 0;

  const showItems = (variation) => {
    if(activeIndex == 0 && variation == -1)
      return;

    let index = activeIndex + variation * itemsPerSwipe;

    if(!items[index])
      return;

    items.forEach((item) => {
      if(variation == 1) {
        item.classList.remove('left');
        item.classList.add('right');
      } else {
        item.classList.remove('right');
        item.classList.add('left');
      };
    });

    activeIndex = index;

    element.querySelectorAll('.item.active').forEach((item) => {
      item.classList.remove('active');
      item.classList.add('inactive');
    });

    for (let i = 0; i < itemsPerSwipe; i++) {
      if(items[activeIndex + i])
        items[activeIndex + i].classList.add('active');
    }
  };

  const setupItems = () => {
    itemsPerSwipe = numberOfElements();

    items.forEach((item, i) => {
      if(i < itemsPerSwipe) {
        item.classList.remove('inactive');
        item.classList.add('active');
      } else {
        item.classList.remove('active');
        item.classList.add('inactive');
      }
    });
  };

  const initNav = () => {
    leftArrow.addEventListener('click', () => { showItems(-1); });
    rightArrow.addEventListener('click', () => { showItems(1); });

    window.addEventListener('resize', setupItems);
    window.addEventListener('load', setupItems);

    initTouchNav(
      element,
      () => { showItems(-1); },
      () => { showItems(1); }
    );
  };

  if(element.querySelector('.arrow.prev'))
    initNav();
}

export { contentCarousel };
