// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import SmoothScroll from 'smooth-scroll';
import "bootstrap";
import Rails from '@rails/ujs';
Rails.start();

import { initCounselors } from '../components/counselors.js';
import { initTestimonials } from '../components/testimonials.js';
import { initAudioLinks } from '../components/audio_links.js';
import { initArticles } from '../components/articles.js';
import { initVideos } from '../components/videos.js';
import { initNavbar } from '../components/navbar.js';
import { initNotice } from '../components/notice.js';
import { initBanner } from '../components/banner.js';
import { initBooks } from '../components/books.js';
import { initUserForm } from '../components/user_form.js';
import { initCaseStudiesForm } from '../components/case_studies_form.js';
import { initRecordedSessionsForm } from '../components/recorded_sessions_form.js';
import { initSupportCallsForm } from '../components/support_calls_form.js';
import { initSeminarsSelect } from '../components/seminars_select.js';
import { initMeetingsSelect } from '../components/meetings_select.js';
import { initClipboard } from '../components/clipboard.js';
import { initCgv } from '../components/cgv.js';
import { initMapbox } from '../plugins/init_mapbox';

document.addEventListener('DOMContentLoaded', () => {
  initMapbox();

  new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    offset: (anchor, toggle) => {
      return document.querySelector('a.nav-link').offsetHeight + 40;
    }
  });

  if(document.querySelector('section.counselors'))
    initCounselors();

  if(document.querySelector('section.testimonials'))
    initTestimonials();

  if(document.querySelector('section.articles'))
    initArticles();

  if(document.querySelector('section.videos'))
    initVideos();

  if(document.getElementById('potential_counselor_counselor_id'))
    initUserForm();

  if(document.querySelector('body.courses.new'))
    initCgv();

  if(document.querySelector('.clipboard'))
    initClipboard();

  if(document.querySelector('div.navbar.large'))
    initNavbar();

  if(document.querySelector('#case_studies_list form'))
    initCaseStudiesForm();

  if(document.querySelector('#recorded_sessions_list form'))
    initRecordedSessionsForm();

  if(document.querySelector('#support_calls_list form'))
    initSupportCallsForm();

  if(document.querySelector('#seminars_list select'))
    initSeminarsSelect();

  if(document.querySelector('#meetings_list select'))
    initMeetingsSelect();

  initNotice();
  initBanner();
  initAudioLinks();
  initBooks();
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
