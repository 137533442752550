const initAudioLinks = () => {
  const audioLinks = document.querySelectorAll('.audio_link');

  if (audioLinks.length > 0) {
    console.log('qwdqw');
    audioLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        let audio = link.parentNode.querySelector('audio');
        audio.classList.remove('hide');
        audio.play();

        link.remove();
      });
    });
  }
}

export { initAudioLinks }
