const initCaseStudiesForm = () => {
  const caseStudiesSubmit = document.querySelector('#case_studies_list input[type=submit]');
  const caseStudiesSelects = document.querySelectorAll('#case_studies_list select');

  caseStudiesSelects.forEach((item) => {
    item.addEventListener('change', () => {
      caseStudiesSubmit.click();
    })
  });
}

export { initCaseStudiesForm }
