const initUserForm = () => {
  const counselorSelect = document.getElementById('potential_counselor_counselor_id');
  const counselorNameField = document.querySelector('.counselor_name_field');

  const toggleCounselorNameField = () => {
    if(counselorSelect.value === "")
      counselorNameField.classList.remove('hide');
    else
      counselorNameField.classList.add('hide');
  };

  counselorSelect.addEventListener('change', toggleCounselorNameField);

  toggleCounselorNameField();
}

export { initUserForm }
