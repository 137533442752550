const initNotice = () => {
  const notices = document.querySelectorAll('.notice');

  const closeNotice = (notice) => {
    notice.classList.remove('active');
  };

  if(notices.length > 0) {
    notices.forEach((notice) => {
      if(notice.dataset.close == 'fade') {
        notice.classList.add('active');
        notice.querySelector('a').addEventListener('click', () => {
          closeNotice(notice);
        });
        setTimeout(() => {
          closeNotice(notice);
        }, 8000);
      } else {
        notice.classList.add('active');
        notice.querySelector('a').addEventListener('click', () => {
          closeNotice(notice);
        });
      }
    })
  }
}

export { initNotice };
