const initBooks = () => {
  document.querySelectorAll('.new_order').forEach((form, i) => {
    form.querySelectorAll('input[type=radio]').forEach((input, i) => {
      input.addEventListener('click', (e) => {
        if(e.target.value === 'true') {
          form.querySelectorAll('.paper').forEach((item) => { item.classList.add('hide') });
          form.querySelectorAll('.ebook').forEach((item) => { item.classList.remove('hide') });
        } else {
          form.querySelectorAll('.paper').forEach((item) => { item.classList.remove('hide') });
          form.querySelectorAll('.ebook').forEach((item) => { item.classList.add('hide') });
        }
      });
    });
  });
}

export { initBooks }
