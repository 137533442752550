const initNavbar = () => {
  const navbar = document.querySelector('div.navbar');
  const navbarCollapse = navbar.querySelector('.navbar-collapse');
  const navbarToggler = document.querySelector('button.navbar-toggler');

  document.addEventListener("scroll", () => {
    if(window.scrollY > 80)
      navbar.classList.remove("large");

    if(window.scrollY <= 10) {
      navbar.classList.add("large");
      if(navbarCollapse.classList.contains('show'))
        navbarToggler.click();
    }
  });
}

export { initNavbar };
