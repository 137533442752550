const initClipboard = () => {
  const clipboardLink = document.querySelector('.clipboard');
  const clipboardIcon = document.querySelector('.clipboard i');
  const clipboardInput = document.querySelector('.link-input input');

  let copied = false;

  clipboardLink.addEventListener('mouseover', () => {
    if(!copied) {
      clipboardIcon.classList.add('fas');
      clipboardIcon.classList.remove('far');
    }
  });

  clipboardLink.addEventListener('mouseout', () => {
    if(!copied) {
      clipboardIcon.classList.remove('fas');
      clipboardIcon.classList.add('far');
    }
  });

  clipboardLink.addEventListener('click', (e) => {
    copied = true;

    clipboardInput.select();
    clipboardInput.setSelectionRange(0, 99999);

    document.execCommand("copy");

    if(clipboardIcon.classList.contains('fa-clipboard-check'))
      return;

    clipboardIcon.classList.remove('fa-clipboard');
    clipboardIcon.classList.add('fas');
    clipboardIcon.classList.add('fa-clipboard-check');
    clipboardLink.dataset.tooltip = clipboardLink.dataset.tooltipAlt;

    fetch(clipboardLink.dataset.shareUrl, {
      method: 'PUT',
      headers: {
        'X-Csrf-Token': document.querySelector('meta[name=csrf-token]').content
      }
    });
  });
};

export { initClipboard };
