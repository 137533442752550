const initMeetingsSelect = () => {
  const meetingsSelect = document.querySelector('#meetings_list select');
  const meetingsBlocks = document.querySelectorAll('#meetings_list .row div');

  const meetingsChange = () => {
    meetingsBlocks.forEach((item, i) => {
      if(item.classList.contains('year_' + meetingsSelect.value))
        item.classList.remove('hide');
      else
        item.classList.add('hide');
    });
  };

  meetingsSelect.addEventListener('change', meetingsChange);

  meetingsChange();
}

export { initMeetingsSelect }
