const initSupportCallsForm = () => {
  const supportCallsSubmit = document.querySelector('#support_calls_list input[type=submit]');
  const supportCallsSelects = document.querySelectorAll('#support_calls_list select');

  supportCallsSelects.forEach((item) => {
    item.addEventListener('change', () => {
      supportCallsSubmit.click();
    })
  });
}

export { initSupportCallsForm }
