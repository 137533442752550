import { contentCarousel } from '../components/content_carousel.js';

const initArticles = () => {
  contentCarousel(
    document.getElementById('articles'),
    () => {
      if(window.innerWidth <= 1024)
        return 1;
      else if(window.innerWidth <= 1280)
        return 2;
      else
        return 3;
    }
  );
}

export { initArticles };
