import { contentCarousel } from '../components/content_carousel.js';

const initVideos = () => {
  contentCarousel(
    document.getElementById('videos'),
    () => {
      if(window.innerWidth <= 768)
        return 1;
      else if(window.innerWidth <= 1440)
        return 2;
      else
        return 3;
    }
  );
};

export { initVideos };
